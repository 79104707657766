#body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

#form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 50%;
}
