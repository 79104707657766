#container{
    display: flex;
    flex-direction: row;
    background-color: pink;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap
}

#cardBox{
    display: flex;
    flex-direction: column;
    border: solid 2px #ccc;
    margin:20px 25px;
    border-radius: 20px;
    padding: 20px;
}