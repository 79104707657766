.su-packs-item-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.su-packs-item-details img {
  width: 400px;
  border: 1px solid #e0e0e0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 100ms ease-out;
  transform-origin: bottom center;
  cursor: pointer;
}

.su-packs-item-details img:hover {
  scale: 1.03;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}

.su-packs-item-choices {
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.choice-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #e0e0e0;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  color: black;
}
.choice-card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 10px;
}
