@media (max-width: 600px) {
  /* Path: src\components\layout\topbar\index.css */
  .pdf-viewer-canvas {
    scale: 0.5;
  }

  .pdf-frame {
    width: 100%;
    height: 100%;
  }
}
