#menuicon {
  transform: scale(2.5);
}

#top-logo {
}

@media (max-width: 768px) {
  /* Path: src\components\layout\topbar\index.css */
  #top-logo {
    margin-left: -20px;
    /* width: 15vw; */
  }
}
