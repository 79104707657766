/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.name-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 2rem 0;
}

.id-container {
  display: flex;
  margin: 2rem 0;
}

.phone-no-container {
  margin: 2rem 0;
}

.gender-container {
  margin: 2rem 0;
}

.class-standing-container {
  margin: 2rem 0;
}

.major-container {
  margin: 2rem 0;
}

.consent-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 2rem;
}

.consent-container span {
  font-family: 'Hiragino Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  font-weight: 600;
  font-style: italic;
  opacity: 0.8;
  font-size: small;
}

.button-container {
  margin: 2rem 0;
}

.button-container button {
  color: white;
} */

.su-packs-main {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

* {
  text-decoration: none;
}

.header h1 {
  font-size: 2.3rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.header .points {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.header .points p {
  font-size: 1.3rem;
  font-weight: 600;
  opacity: 0.8;
}

.su-packs-container {
  padding-top: 2rem;
}
.su-packs-container h2 {
  font-size: 2rem;
  text-align: center;
}

.su-packs-categories {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.su-packs-category {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  width: 400px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.su-packs-category img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 10px;
}

.su-packs-category:hover {
  background-color: #f5f5f5;
}

.su-packs-category:focus {
  box-shadow: 0 0 0 3px #ff66c4;
}

.su-packs-category h1 {
  font-size: 1.5rem;
  font-weight: 800;
}

.su-packs-items {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.su-packs-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #e0e0e0;
  width: 400px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.su-packs-item:hover {
  background-color: #f5f5f5;
}

.su-packs-item:focus {
  box-shadow: 0 0 0 3px #ff66c4;
}

.su-packs-item img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 10px;
}

.su-packs-item p {
  font-weight: 600;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .su-packs-category {
    width: 100%;
  }
}

.su-packs-checkout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}
