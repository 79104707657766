#body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

#submit {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
}

#submit:hover {
  background-color: #ff66c4;
  cursor: pointer;
  border-color: #ff66c4;
}

#form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
